/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-29",
    versionChannel: "nightly",
    buildDate: "2024-02-29T00:05:52.876Z",
    commitHash: "a03355458f25ce64fea37708ff63876db829b152",
};
